import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

// TransitionComponent 动画
//  Fade | SlideTransition | GrowTransition

// type 消息类型
// success | warning | info | error
export default function Alert({
  closeBar, closeAlert, open, type, text,
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={1500}
      onClose={closeBar}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert elevation={6} variant="filled" onClose={closeAlert} severity={type}>
        {text}
      </MuiAlert>
    </Snackbar>
  );
}
