import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import {
  CardHeader,
  Divider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  Button,
  Tooltip,
} from '@material-ui/core';
import { blockListType } from '../../../types';

const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
    blockTable: {
		},
    tableRoot: {
      padding: '0 16px',
    },
    linkButton: {
			'&:hover': {
				color: theme.palette.primary.main,
			},
    }
	};
};


export class BlockActivityCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

  // 跳区块详情页
  goBlockDetail = (row) => {
    this.props.history.push({
      pathname: '/blockDetail',
      state: row
    });
  }

	render() {
		const { blockList, classes } = this.props;
		return (
			<div className={classes.blockTable}>
        <CardHeader title={<Typography variant="subtitle1">最近区块概览</Typography>} />
        <Divider />
        <TableContainer classes={{ root: classes.tableRoot }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>区块序号</TableCell>
                <TableCell>创建时间</TableCell>
                <TableCell>交易数量</TableCell>
                <TableCell>区块哈希</TableCell>
                <TableCell>区块大小</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { blockList && blockList.length > 0 && blockList.map((row) => (
                <TableRow key={row.blocknum}>
                  <TableCell>
                    {row.blocknum}
                  </TableCell>
                  <TableCell>{moment(row.createdt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  <TableCell>{row.txcount}</TableCell>
                  <TableCell>
                    <a className="router" onClick={() => this.goBlockDetail(row)}>
                      <Tooltip title={row.blockhash} arrow placement="top">
                        <Typography classes={{ root: classes.hashCellRoot }} variant="inherit" noWrap={true}>
                          {row.blockhash}
                        </Typography>
                      </Tooltip>
                    </a>
                  </TableCell>
                  <TableCell>{row.blksize} KB</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Button
            className={classes.linkButton}
            to="/blocks"
            variant="outlined"
            size="medium"
            color="primary"
            component={RouterLink}
          >
            查看更多
          </Button>
        </Box>
			</div>
		);
	}
}

BlockActivityCard.propTypes = {
	blockList: blockListType.isRequired,
};

export default withStyles(styles)(BlockActivityCard);
