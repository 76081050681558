import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Menu, MenuItem, Typography } from '@material-ui/core';

// project imports
import MainCard from '../../../common/MainCard';
// assets
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
// 数字变化动画
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
TweenOne.plugins.push(Children);

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.warning.light,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      background: theme.palette.warning.main,
      borderRadius: '50%',
      top: '-85px',
      right: '-95px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px'
      }
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      background: theme.palette.warning.main,
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px'
      }
    }
  },
  content: {
    padding: '20px !important'
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    backgroundColor: theme.palette.warning.main,
    marginTop: '8px'
  },
  cardHeading: {
    fontSize: '2.125rem',
    fontWeight: 500,
    marginRight: '8px',
    marginTop: '14px',
    marginBottom: '6px'
  },
  subHeading: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.warning.contrastText
  },
}));

//===========================|| DASHBOARD DEFAULT - NODES BOX ||===========================//

const NodesBox = ({ peerCount }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <MainCard border={false} className={classes.card} contentClass={classes.content}>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Avatar variant="rounded" className={classes.avatar}>
                  <AccountTreeOutlinedIcon fontSize="inherit" />
                </Avatar>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Typography className={classes.cardHeading}>
                  <TweenOne
                    animation={{
                      Children: {
                        value: peerCount,
                        floatLength: 0,
                      },
                      duration: 700,
                    }}
                  >0</TweenOne>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ mb: 1.25 }}>
            <Typography className={classes.subHeading}>节点数</Typography>
          </Grid>
        </Grid>
      </MainCard>
    </React.Fragment>
  );
};

export default NodesBox;
