// SPDX-License-Identifier: Apache-2.0
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import notFoundImg from '../../static/images/illustration_404.svg';
import { Box, Button, Typography, Container } from '@material-ui/core';

const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		container: {
			width: '100%',
      marginTop: 120,
      minHeight: window.innerHeight - 205,
      backgroundColor: '#F9F9F9',
		},
		linkButton: {
			'&:hover': {
				color: '#FFF',
			},
    }
	};
};

export const PageNotFound = ({ classes }) => (
	<div className={classes.container}>
		<Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h4" paragraph>
          页面未找到!
        </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
				很抱歉，我们未找到该页面，请检查页面地址是否输入有误。
      </Typography>
      <Box
        component="img"
        src={notFoundImg}
        sx={{ height: 260, mx: 'auto', my: { xs: 4, sm: 8 } }}
      />
      <Button className={classes.linkButton} to="/" size="large" color="primary" variant="contained" component={RouterLink}>
        返回首页
      </Button>
    </Box>
	</div>
);

export default withStyles(styles)(PageNotFound);
