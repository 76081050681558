import React from 'react';
import { useTheme, styled } from '@material-ui/core/styles';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from './charts';

const CHART_HEIGHT = 332;
const LEGEND_HEIGHT = 56;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

export default function OrgPieChart({ chartValue, chartLabel }) {
	const theme = useTheme();

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
			theme.palette.purple.main,
			theme.palette.warning.main,
			theme.palette.primary.main,
      theme.palette.secondary.main,
    ],
    labels: chartLabel,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        title: {
          formatter: (seriesName) => `${seriesName} ：`
        }
      }
    },
  });

  return (
    <div style={{ padding: '8px'}}>
			<ChartWrapperStyle dir="ltr">
	      <ReactApexChart
	        type="donut"
	        series={chartValue}
	        options={chartOptions}
	        height={290}
	      />
			</ChartWrapperStyle>
    </div>
  );
}
