// ----------------------------------------------------------------------

export default function Other(theme) {
  return {
    MuiSelect: {
      selectMenu: {
        minHeight: 'auto'
      }
    }
  };
}
