import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import QueueAnim from 'rc-queue-anim';
import View from '../Styled/View';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Grid,
  Box,
  Tooltip,
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import Alert from './components/Alert';
import channelIcon from '../../static/images/ico-cloud.png';
import { channelsType } from '../types';

const styles = theme => {
	return {
    blockTable: {
      paddingBottom: '16px',
		},
    tableRoot: {
      padding: '0 16px 16px',
    },
    blockContent: {
      padding: '24px'
    },
    copyField: {
      display: 'flex',
    },
    copyPointer: {
      cursor: 'pointer',
      marginLeft: '8px'
    }
	};
};

export const ChannelsView = ({ channels, classes }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <View>
      <QueueAnim type="bottom">
        <Card key='card'>
          <CardHeader title={<Typography variant="subtitle1">通道概况</Typography>} />
          <Divider />
          {
            channels.map((row, index) => {
              return (
                <div className={classes.blockContent}>
                  <Row>
                    <Col sm="1">
                      <Box
                        component="img"
                        src={channelIcon}
                        sx={{ height: 48, mx: 'auto', borderRadius: '12px' }}
                      />
                    </Col>
                    <Col>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography variant="subtitle2" color="textSecondary">通道名称</Typography>
                          <Typography variant="subtitle1">{row.channelname}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography variant="subtitle2" color="textSecondary">创建时间</Typography>
                          <Typography variant="subtitle1">{moment(row.createdat).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography variant="subtitle2" color="textSecondary">区块数</Typography>
                          <Typography variant="h4">{row.blocks}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography variant="subtitle2" color="textSecondary">交易数</Typography>
                          <Typography variant="h4">{row.transactions}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography variant="subtitle2" color="textSecondary">通道ID</Typography>
                          <Typography variant="subtitle1">#{row.id}</Typography>
                        </Grid>
                        <Grid item xs={11} sm={5} md={8}>
                          <Typography variant="subtitle2" color="textSecondary">创世哈希</Typography>
                          <div className={classes.copyField}>
                            <Tooltip title={row.channel_genesis_hash} arrow placement="top">
                              <Typography variant="subtitle2" noWrap={true}>{row.channel_genesis_hash}</Typography>
                            </Tooltip>
                            <CopyToClipboard text={row.channel_genesis_hash} onCopy={handleClick}>
                              <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                            </CopyToClipboard>
                          </div>
                        </Grid>
                      </Grid>
                    </Col>
                  </Row>
                </div>
              )
          })
        }
        </Card>
      </QueueAnim>
      <Alert
        type="success"
        text="数据已复制"
        open={open}
        closeBar={handleClose}
      />
    </View>
  );
}

ChannelsView.propTypes = {
  channels: channelsType.isRequired,
};

export default withStyles(styles)(ChannelsView);
