import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Menu, MenuItem, Typography } from '@material-ui/core';

// project imports
import MainCard from '../../../common/MainCard';
// assets
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppOutlined';
import PictureAsPdfTwoToneIcon from '@material-ui/icons/PictureAsPdfOutlined';
// 数字变化动画
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
TweenOne.plugins.push(Children);

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      background: theme.palette.primary.main,
      borderRadius: '50%',
      top: '-85px',
      right: '-95px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px'
      }
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      background: theme.palette.primary.main,
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px'
      }
    }
  },
  content: {
    padding: '20px !important'
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    backgroundColor: theme.palette.primary.main,
    marginTop: '8px'
  },
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.lighter,
    cursor: 'pointer',
    zIndex: 1
  },
  cardHeading: {
    fontSize: '2.125rem',
    fontWeight: 500,
    marginRight: '8px',
    marginTop: '14px',
    marginBottom: '6px'
  },
  subHeading: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.primary.contrastText
  },
  menuItem: {
    marginRight: '14px',
    fontSize: '1.25rem'
  }
}));

//===========================|| DASHBOARD DEFAULT - BLOCK BOX ||===========================//

const BlockBox = ({ latestBlock }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <MainCard border={false} className={classes.card} contentClass={classes.content}>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Avatar variant="rounded" className={classes.avatar}>
                  <ViewAgendaOutlinedIcon fontSize="inherit" />
                </Avatar>
              </Grid>
              {
                /*
                <Grid item>
                  <Avatar
                    variant="rounded"
                    className={classes.avatarRight}
                    aria-controls="menu-earning-card"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreHorizIcon fontSize="inherit" />
                  </Avatar>
                    <Menu
                      id="menu-earning-card"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      variant="selectedMenu"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <GetAppTwoToneIcon fontSize="inherit" className={classes.menuItem} /> 下载数据
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <PictureAsPdfTwoToneIcon fontSize="inherit" className={classes.menuItem} /> 查看详情
                      </MenuItem>
                    </Menu>
                </Grid>
                */
              }
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Typography className={classes.cardHeading}>
                  <TweenOne
                    animation={{
                      Children: {
                        value: latestBlock,
                        floatLength: 0,
                      },
                      duration: 700,
                    }}
                  >0</TweenOne>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ mb: 1.25 }}>
            <Typography className={classes.subHeading}>区块数</Typography>
          </Grid>
        </Grid>
      </MainCard>
    </React.Fragment>
  );
};

export default BlockBox;
