/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { zhCN } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { themeSelectors } from '../../state/redux/theme';
import '../../static/css/main.css';
import '../../static/css/main-dark.css';
import '../../static/css/media-queries.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'font-awesome/css/font-awesome.min.css';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

class Theme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    const { mode, children } = this.props;
    return (
      <MuiThemeProvider theme={this.getTheme(mode)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MuiThemeProvider>
    );
  }

  getTheme(mode) {
    const theme = createTheme({
      palette,
      shape,
      typography,
      shadows,
      customShadows,
    }, zhCN);
    theme.overrides = componentsOverride(theme);
    return theme;
  }
}

const { modeSelector } = themeSelectors;

export default connect(state => ({
  mode: modeSelector(state),
}))(Theme);
