import React from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from './charts';
import { chartDataType } from '../types';

export default function BlockChart({ chartData }) {
  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      height: 320,
      type: 'area'
    },
    yaxis: {
      max: chartData.dataMax,
      labels: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        }
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: 'HH:mm yy/MM/dd',
      },
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <div style={{ padding: '8px'}}>
			<ReactApexChart
				type="area"
				series={[{
					name: '区块数',
					data: chartData.blockData
				}]}
				options={chartOptions}
				height={320}
			/>
    </div>
  );
}

BlockChart.propTypes = {
  chartData: chartDataType.isRequired,
};
