/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { Grid, Card, Avatar, Typography } from '@material-ui/core';
import {
  BlockBox,
	TransactionBox,
	NodesBox,
	ChaincodeBox,
  BlockChartCard,
  TransactionChartCard,
  OrgPieChartCard,
  BlockActivityCard,
  PeersHealthCard
} from './components/Dashboard';
import PeersHealth from '../Lists/PeersHealth';
import TimelineStream from '../Lists/TimelineStream';
import ChartStats from '../Charts/ChartStats';
import OrgPieChart from '../Charts/OrgPieChart';
import {
	blockListType,
	dashStatsType,
	peerStatusType,
	transactionByOrgType
} from '../types';

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		background: {
			backgroundColor: '#F9F9F9'
		},
    errorContent: {
      width: '100%',
      marginTop: 120,
      minHeight: window.innerHeight - 205,
      display: 'flex',
	    justifyContent: 'center',
	    alignItems: 'center',
    },
		view: {
      paddingTop: 120,
			paddingLeft: 0,
			width: '84%',
			marginLeft: '8%',
			marginRight: '8%'
		},
		blocks: {
			height: 175,
			marginBottom: 20,
			backgroundColor: dark ? '#453e68' : '#ffffff',
			boxShadow: dark ? '1px 2px 2px rgb(215, 247, 247)' : undefined
		},
		count: {
			marginTop: '55%',
			color: dark ? '#ffffff' : undefined
		},
		statistic: {
			display: 'block',
			float: 'left',
			height: '100%',
			width: '25%',
			textAlign: 'center',
			fontSize: '18pt',
			color: dark ? '#ffffff' : '#000000'
		},
		vdivide: {
			'&::after': {
				borderRight: `2px ${dark ? 'rgb(40, 36, 61)' : '#dff1fe'} solid`,
				display: 'block',
				height: '45%',
				bottom: '55%',
				content: "' '",
				position: 'relative'
			}
		},
		avatar: {
			justifyContent: 'center',
			marginLeft: '60%',
			marginTop: '65%'
		},
		node: {
			color: dark ? '#183a37' : '#21295c',
			backgroundColor: dark ? 'rgb(104, 247, 235)' : '#858aa6'
		},
		block: {
			color: dark ? '#1f1a33' : '#004d6b',
			backgroundColor: dark ? 'rgb(106, 156, 248)' : '#b9d6e1'
		},
		chaincode: {
			color: dark ? 'rgb(121, 83, 109)' : '#407b20',
			backgroundColor: dark ? 'rgb(247, 205, 234)' : '#d0ecda'
		},
		transaction: {
			color: dark ? 'rgb(216, 142, 4)' : '#ffa686',
			backgroundColor: dark ? 'rgb(252, 224, 174)' : '#ffeed8'
		},
		section: {
			marginBottom: '24px',
      overflow: 'unset',
		},
		center: {
			textAlign: 'center'
		},
    blockContent: {
      margin: '0px auto 24px'
    }
	};
};

export class DashboardView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notifications: [],
			hasDbError: false
		};
	}

	componentWillMount() {
		const {
			blockList,
			dashStats,
			peerStatus,
			transactionByOrg,
			blockActivity
		} = this.props;
		if (
			blockList === undefined ||
			dashStats === undefined ||
			peerStatus === undefined ||
			blockActivity === undefined ||
			transactionByOrg === undefined
		) {
			this.setState({ hasDbError: true });
		}
	}

	componentDidMount() {
		const { blockActivity } = this.props;
		this.setNotifications(blockActivity);
	}

	componentWillReceiveProps() {
		const { blockActivity } = this.props;
		this.setNotifications(blockActivity);
	}

	setNotifications = blockList => {
		const notificationsArr = [];
		if (blockList !== undefined) {
			for (let i = 0; i < 5 && blockList && blockList[i]; i += 1) {
				const block = blockList[i];
				const notify = {
					title: `Block ${block.blocknum} `,
					type: 'block',
          blocknum:block.blocknum,
					time: block.createdt,
					txcount: block.txcount,
					datahash: block.datahash,
					blockhash: block.blockhash,
					channelName: block.channelname
				};
				notificationsArr.push(notify);
			}
		}
		this.setState({ notifications: notificationsArr });
	};

	render() {
		const { dashStats, peerStatus, blockActivity, transactionByOrg } = this.props;
		const { hasDbError, notifications } = this.state;
    const { classes } = this.props;
		if (hasDbError) {
			return (
				<div className={classes.errorContent}>
					<h4>
						请检查您的网络或数据库配置，确认无误后请再次尝试
					</h4>
				</div>
			);
		}
		return (
			<div className={classes.background}>
				<div className={classes.view}>
          {/* 区块 交易 节点 合约 展示*/}
          <div className={classes.blockContent}>
    				<Grid container spacing={3}>
    					<Grid item xs={12} sm={6} md={3}>
    						<BlockBox
    							latestBlock={dashStats.latestBlock}
    						/>
    					</Grid>
    					<Grid item xs={12} sm={6} md={3}>
    						<TransactionBox
    							txCount={dashStats.txCount}
    						/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
    						<NodesBox
    							peerCount={dashStats.peerCount}
    						/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
    						<ChaincodeBox
    							chaincodeCount={dashStats.chaincodeCount}
    						/>
              </Grid>
    				</Grid>
          </div>
          {
            /*
            <Row>
  						<Col sm="12">
  							<Card className={classes.blocks}>
  								<div className={`${classes.statistic} ${classes.vdivide}`}>
  									<Row>
  										<Col sm="4">
  											<Avatar className={`${classes.avatar} ${classes.block}`}>
  												<FontAwesome name="cube" />
  											</Avatar>
  										</Col>
  										<Col sm="4">
  											<h1 className={classes.count}>{dashStats.latestBlock}</h1>
  										</Col>
  									</Row>
  									BLOCKS
  								</div>
  								<div className={`${classes.statistic} ${classes.vdivide}`}>
  									<Row>
  										<Col sm="4">
  											<Avatar className={`${classes.avatar} ${classes.transaction}`}>
  												<FontAwesome name="list-alt" />
  											</Avatar>
  										</Col>
  										<Col sm="4">
  											<h1 className={classes.count}>{dashStats.txCount}</h1>
  										</Col>
  									</Row>
  									TRANSACTIONS
  								</div>
  								<div className={`${classes.statistic} ${classes.vdivide}`}>
  									<Row>
  										<Col sm="4">
  											<Avatar className={`${classes.avatar} ${classes.node}`}>
  												<FontAwesome name="users" />
  											</Avatar>
  										</Col>
  										<Col sm="4">
  											<h1 className={classes.count}>{dashStats.peerCount}</h1>
  										</Col>
  									</Row>
  									NODES
  								</div>
  								<div className={classes.statistic}>
  									<Row>
  										<Col sm="4">
  											<Avatar className={`${classes.avatar} ${classes.chaincode}`}>
  												<FontAwesome name="handshake-o" />
  											</Avatar>
  										</Col>
  										<Col sm="4">
  											<h1 className={classes.count}>{dashStats.chaincodeCount}</h1>
  										</Col>
  									</Row>
  									CHAINCODES
  								</div>
  							</Card>
  						</Col>
  					</Row>
            */
          }
          {/* 最近区块 最近交易 分时展示图表 */}
          <Row>
            <Col sm="6">
              <Card className={classes.section}>
                <BlockChartCard />
              </Card>
            </Col>
            <Col sm="6">
              <Card className={classes.section}>
                <TransactionChartCard />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className={classes.section}>
                <BlockActivityCard
                  blockList={blockActivity}
                  history={this.props.history}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Card className={classes.section}>
                <PeersHealthCard peerStatus={peerStatus} />
              </Card>
            </Col>
            <Col sm="6">
              <Card className={classes.section}>
                <OrgPieChartCard transactionByOrg={transactionByOrg} />
              </Card>
            </Col>
          </Row>
          {
            /*
					<Row>
            <Col sm="6">
              <Card className={classes.section}>
                <TimelineStream
                  notifications={notifications}
                  blockList={blockActivity}
                />
              </Card>
            </Col>
              <Col sm="6">
                <Card className={classes.section}>
                  <ChartStats />
  							</Card>
              </Col>
					</Row>
          */
        }
				</div>
			</div>
		);
	}
}

DashboardView.propTypes = {
	blockList: blockListType.isRequired,
	dashStats: dashStatsType.isRequired,
	peerStatus: peerStatusType.isRequired,
	transactionByOrg: transactionByOrgType.isRequired
};

export default withStyles(styles)(DashboardView);
