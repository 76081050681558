import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import {
  CardHeader,
  Divider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import Label from '../../../common/Label';
import { peerStatusType } from '../../../types';
import { PeerTypeMap } from '../../common/TypeMap';

const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
    blockTable: {
		},
    tableRoot: {
      padding: '0 16px 16px',
      maxHeight: 350,
    }
	};
};


export class PeersHealthCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { peerStatus, classes } = this.props;
		return (
			<div className={classes.blockTable}>
        <CardHeader title={<Typography variant="subtitle1">节点状态</Typography>} />
        <Divider />
        <TableContainer classes={{ root: classes.tableRoot }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>节点名称</TableCell>
                <TableCell>节点类型</TableCell>
                <TableCell>连接状态</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {peerStatus.map((row) => (
                <TableRow key={row.server_hostname}>
                  <TableCell>{row.server_hostname && row.server_hostname.split(":")[0]}</TableCell>
                  <TableCell>
                    {
                      PeerTypeMap[row.peer_type] ?
                      `${PeerTypeMap[row.peer_type]}（${row.peer_type}）`
                      :
                      row.peer_type
                    }
                  </TableCell>
                  {
                    row.ledger_height_unsigned == true ?
                    <TableCell>
                      <Label variant="ghost" color="success">已连接</Label>
                    </TableCell>
                    :
                    (
                      row.ledger_height_unsigned == false ?
                      <TableCell>
                        <Label variant="ghost" color="error">未连接</Label>
                      </TableCell>
                      :
                      <TableCell>{row.ledger_height_unsigned}</TableCell>
                    )
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
			</div>
		);
	}
}

PeersHealthCard.propTypes = {
	peerStatus: peerStatusType.isRequired
};

export default withStyles(styles)(PeersHealthCard);
