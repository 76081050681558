/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clientJson from '../../../package.json';
import FabricVersion from '../../FabricVersion';
import BeianImg from '../../static/images/ico-beian.png';

const date = new Date();
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		root: {
			margin: 0,
		},
		footer: {
			height: '80px',
			lineHeight: '80px',
			color: '#CCC',
			display: 'flex',
			justifyContent: 'center',
		},
		loginFooterBA: {
			display: 'flex',
			justifyContent: 'center',
			color: '#CCC',
			alignItems: 'center',
		},
		loginFooterDivider: {
			margin: '0 12px',
		},
		loginFooterBAImg: {
			width: '16px',
			marginRight: '8px',
		}
	};
};

const FooterView = ({ classes }) => (
	<div className={classes.root}>
		<div>
			<div className={classes.footer}>
				Copyright © 2020-{date.getFullYear()} 上海超润网络科技有限公司
				<div className={classes.loginFooterDivider}>|</div>
				<a className={classes.loginFooterBA} target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009951">
					<img className={classes.loginFooterBAImg} src={BeianImg} />
					<span>沪公网安备 31010402009951号</span>
				</a>
				<div className={classes.loginFooterDivider}>|</div>
				区块链信息服务备案 沪网信备31012022801483150014号
				{
					/*
					{'Hyperledger Explorer Client Version: '}
					{clientJson.version}
					&emsp;
					{'Fabric Compatibility: '} {FabricVersion.map(v => v)}
					*/
				}
			</div>
		</div>
	</div>
);

export default withStyles(styles)(FooterView);
