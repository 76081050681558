import { useRef, useState } from 'react';
// material
import { alpha } from '@material-ui/core/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton, Button, Divider, Typography } from '@material-ui/core';
// components
import AvatarImg from '../../static/images/avatar_default.jpg';
import MenuPopover from '../common/MenuPopover';

// ----------------------------------------------------------------------

export default function LanguagePopover(props) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    props.onOpen();
    setOpen(true);
  };

  const handleClose = (option) => {
    props.onClose(option);
    setOpen(false);
  };

  return (
    <>
      {
        /*
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          style={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          {props.selected.label}
        </IconButton>
        */
      }
      <Button
        color="primary"
        ref={anchorRef}
        onClick={handleOpen}
      >
        {props.selected.label}
      </Button>
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" style={{ marginBottom: 8 }}>当前通道</Typography>
          </Box>
        </Box>

        <Divider />
        <Box sx={{ py: 1 }}>
          {props?.options &&  props.options.length > 0  && props.options.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === props.selected.value}
              onClick={() => handleClose(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              {
                /*
                <ListItemIcon>
                  <Box component="img" alt={option.label} src={option.icon} />
                </ListItemIcon>
                */
              }
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
