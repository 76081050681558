export const OrgsMap = {
  'chuangyu': '创域',
  'chaorun': '超润',
  'tongshuai': '统帅',
  'chuangyusmart': '创域智慧',
  'haiyu': '海域',
  'langyuzs': '朗域',
  'ciyujc': '瓷语',
  'shsjww': '室间万物',
}

export const PeerTypeMap = {
  'ORDERER': '排序节点',
  'PEER': '参与方节点',
}

export const TransTypeMap = {
  'ENDORSER_TRANSACTION': '背书交易',
}
