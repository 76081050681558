import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import QueueAnim from 'rc-queue-anim';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import View from '../Styled/View';
import Label from '../common/Label';
import Alert from './components/Alert';
import JsonTree from './components/JsonTree'
import transIcon from '../../static/images/ico-transaction.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import {
  currentChannelType,
  getTransactionType,
  transactionType
} from '../types';
import { OrgsMap, TransTypeMap } from './common/TypeMap';

const styles = theme => {
	return {
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    blockContent: {
      padding: '24px'
    },
    copyField: {
      display: 'flex',
    },
    copyPointer: {
      cursor: 'pointer',
      marginLeft: '8px'
    },
    dataBox: {
      marginTop: '24px'
    }
	};
};

export class TransactionDetail extends Component {
  constructor(props) {
		super(props);
		this.state = {
      txHash: null,
		};
	}

  componentWillMount() {
    const params = this.props.match?.params;
    this.setState({
      txHash: params.hash
    }, () => {
      this.getTransactionDetail(params.hash);
    })
	}

  componentDidUpdate(prevProps, prevState) {
		if (this.props.currentChannel !== prevProps.currentChannel) {
		}
	}

  // 获取交易详情
  getTransactionDetail = async txId => {
    const { currentChannel, getTransaction } = this.props;
    await getTransaction(currentChannel, txId);
  }

  // 处理背书人员数组
  formatEndorser = (arr) => {
    var reg = /\"(.*?)\"/g;
    let newArr = arr.match(reg) || []; // 截取引号内字符
    let endorStr = '';
    for (let i =0; i<newArr.length; i++) {
      if (i>0) endorStr+= '，'
      let merId = newArr[i].replace(/\"/g, '');
      endorStr+= OrgsMap[merId] || merId;
    }
    return endorStr
  }

  handleClick = () => {
    this.setState({ showAlert: true })
  }

  render() {
    const {
      classes,
      currentChannel,
      transaction,
    } = this.props;
    const { showAlert } = this.state;

    return (
      <View>
        <QueueAnim type="bottom">
          <Card key="card">
            <CardHeader
              title={
                <div className={classes.cardHeader}>
                  <Box
                    component="img"
                    src={transIcon}
                    sx={{ height: 32, mr: '16px' }}
                  />
                  <Typography variant="subtitle1">交易详情</Typography>
                </div>
              }
            />
            <Divider />
            <div className={classes.blockContent}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="subtitle2" color="textSecondary">交易哈希</Typography>
                  <div className={classes.copyField}>
                    <Typography variant="subtitle1" noWrap={true}>{transaction?.txhash}</Typography>
                    <CopyToClipboard text={transaction?.txhash} onCopy={this.handleClick}>
                      <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                    </CopyToClipboard>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">创建时间</Typography>
                  <Typography variant="subtitle1">{moment(transaction?.createdt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">创建者</Typography>
                  <Typography variant="subtitle1">{OrgsMap[transaction?.creator_msp_id] || transaction?.creator_msp_id}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">交易类型</Typography>
                  <Typography variant="subtitle1">{TransTypeMap[transaction?.type] || transaction?.type }</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">交易有效性</Typography>
                  <Label
                    variant="ghost"
                    color={(transaction?.validation_code === 'VALID' && 'success') || 'error'}
                  >
                    {transaction?.validation_code === 'VALID' ? '有效' : '无效'}
                  </Label>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">链码名</Typography>
                  <Typography variant="subtitle1">{transaction?.chaincodename}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">背书成员</Typography>
                  <Typography variant="subtitle1">
                    {
                     transaction?.endorser_msp_id && this.formatEndorser(transaction?.endorser_msp_id)
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">通道名</Typography>
                  <Typography variant="subtitle1">{transaction?.channelname}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="subtitle2" color="textSecondary">提案哈希</Typography>
                  <div className={classes.copyField}>
                    <Typography variant="subtitle1" noWrap={true}>{transaction?.payload_proposal_hash}</Typography>
                    <CopyToClipboard text={transaction?.payload_proposal_hash} onCopy={this.handleClick}>
                      <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                    </CopyToClipboard>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
          <Card key="card1" className={classes.dataBox}>
            <CardHeader
              title={<Typography variant="subtitle1" >交易数据</Typography>}
            />
            <Divider />
            <div className={classes.blockContent}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">读入数据</Typography>
                  <JsonTree
                    data={transaction?.read_set}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">写入数据</Typography>
                  <JsonTree
                    data={transaction?.write_set}
                  />
                </Grid>
              </Grid>
            </div>
          </Card>
        </QueueAnim>
        <Alert
          type="success"
          text="数据已复制"
          open={showAlert}
          closeBar={() => this.setState({ showAlert: false })}
        />
      </View>
    );
  }
}

TransactionDetail.propTypes = {
  currentChannel: currentChannelType.isRequired,
  getTransaction: getTransactionType.isRequired,
  transaction: transactionType,
};

TransactionDetail.defaultProps = {
	transaction: null
};

export default withStyles(styles)(TransactionDetail);
