/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import Logo from '../../static/images/cr-logo.png';
import LoadingIcon from '../../static/images/loading.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { chartOperations, chartSelectors } from '../../state/redux/charts';
import { tableOperations } from '../../state/redux/tables';
import { authOperations } from '../../state/redux/auth';
import {
	currentChannelType,
	getBlockListType,
	getBlocksPerHourType,
	getBlocksPerMinType,
	getChaincodeListType,
	getChannelListType,
	getChannelType,
	getChannelsType,
	getDashStatsType,
	getPeerListType,
	getPeerStatusType,
	getTransactionByOrgType,
	getTransactionListType,
	getTransactionPerHourType,
	getTransactionPerMinType,
	getUserListType
} from '../types';

const {
	blockPerHour,
	blockPerMin,
	blockActivity,
	channel,
	channelList,
	dashStats,
	peerStatus,
	transactionByOrg,
	transactionPerHour,
	transactionPerMin /* ,
	userList */
} = chartOperations;

const {
	blockList,
	chaincodeList,
	channels,
	peerList,
	transactionList
} = tableOperations;

const { userlist } = authOperations;

const { currentChannelSelector } = chartSelectors;

const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	/* eslint-disable */
	return {
		background: {
			backgroundColor: '#F9F9F9',
			display: 'flex',
	    justifyContent: 'center',
	    alignItems: 'center',
	    flexDirection: 'column',
		},
		content: {
			marginTop: '30vh',
			'& > h1': {
				fontSize: '40pt'
			},
			'& > div': {
				marginLeft: 80
			}
		},
		errorContent: {
			width: '100%',
      marginTop: 120,
      minHeight: window.innerHeight - 205,
      display: 'flex',
	    justifyContent: 'center',
	    alignItems: 'center',
    },
		LoadingIcon: {
			width: 120,
			margin: '24px auto',
		}
	};
	/* eslint-enable */
};

export class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logoStyle: {
				width: '200px',
				height: '50px',
				marginBottom: '24px',
			},
			hasDbError: false
		};
	}

	async componentDidMount() {
		const {
			getBlockList,
			getBlocksPerHour,
			getBlocksPerMin,
			getChaincodeList,
			getChannel,
			getChannelList,
			getChannels,
			getDashStats,
			getPeerList,
			getPeerStatus,
			getBlockActivity,
			getTransactionByOrg,
			getTransactionList,
			getTransactionPerHour,
			getTransactionPerMin,
			updateLoadStatus,
			userlist: userlistData
			// getUserList
		} = this.props;
		await getChannel();
		const { currentChannel } = this.props;

		const promiseTimeout = setTimeout(() => {
			this.setState({ hasDbError: true });
		}, 60000);

		const timeQuery = `from=${new Date(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm')).toString()}&&to=${new Date(
			moment().format('YYYY-MM-DDTHH:mm')
		).toString()}`;

		await Promise.all([
			getBlockList(currentChannel, timeQuery),
			getBlocksPerHour(currentChannel),
			getBlocksPerMin(currentChannel),
			getChaincodeList(currentChannel),
			getChannelList(currentChannel),
			getChannels(),
			getDashStats(currentChannel),
			getPeerList(currentChannel),
			getPeerStatus(currentChannel),
			getBlockActivity(currentChannel),
			getTransactionByOrg(currentChannel),
			getTransactionList(currentChannel, timeQuery),
			getTransactionPerHour(currentChannel),
			getTransactionPerMin(currentChannel),
			userlistData()
		]);
		clearTimeout(promiseTimeout);
		updateLoadStatus();
	}

	render() {
		const { hasDbError, logoStyle } = this.state;
		if (hasDbError) {
			return (
				<div className={classes.errorContent}>
					<h4>
						请检查您的网络或数据库配置，确认无误后请再次尝试
					</h4>
				</div>
			);
		}
		const { classes } = this.props;
		return (
			<div className={classes.background}>
				<div className={classes.content}>
					<img src={Logo} style={logoStyle} alt="超润网络科技" />
					<img className={classes.LoadingIcon} src={LoadingIcon} />
					{
						/*
						<CircularProgress />
						*/
					}
				</div>
			</div>
		);
	}
}

LandingPage.propTypes = {
	currentChannel: currentChannelType,
	getBlockList: getBlockListType.isRequired,
	getBlocksPerHour: getBlocksPerHourType.isRequired,
	getBlocksPerMin: getBlocksPerMinType.isRequired,
	getChaincodeList: getChaincodeListType.isRequired,
	getChannelList: getChannelListType.isRequired,
	getChannel: getChannelType.isRequired,
	getChannels: getChannelsType.isRequired,
	getDashStats: getDashStatsType.isRequired,
	getPeerList: getPeerListType.isRequired,
	getPeerStatus: getPeerStatusType.isRequired,
	getTransactionByOrg: getTransactionByOrgType.isRequired,
	getTransactionList: getTransactionListType.isRequired,
	getTransactionPerHour: getTransactionPerHourType.isRequired,
	getTransactionPerMin: getTransactionPerMinType.isRequired,
	userlist: getUserListType.isRequired
};

LandingPage.defaultProps = {
	currentChannel: null
};

const mapStateToProps = state => {
	return {
		currentChannel: currentChannelSelector(state)
	};
};

const mapDispatchToProps = {
	getBlockList: blockList,
	getBlocksPerHour: blockPerHour,
	getBlocksPerMin: blockPerMin,
	getChaincodeList: chaincodeList,
	getChannelList: channelList,
	getChannel: channel,
	getChannels: channels,
	getDashStats: dashStats,
	getPeerList: peerList,
	getPeerStatus: peerStatus,
	getBlockActivity: blockActivity,
	getTransactionByOrg: transactionByOrg,
	getTransactionList: transactionList,
	getTransactionPerHour: transactionPerHour,
	getTransactionPerMin: transactionPerMin,
	userlist: userlist
	//getUserList: userList
};

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(LandingPage);
export default withStyles(styles)(connectedComponent);
