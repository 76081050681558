import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import QueueAnim from 'rc-queue-anim';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Box,
  Grid
} from '@material-ui/core';
import View from '../Styled/View';
import blockIcon from '../../static/images/ico-block.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import Alert from './components/Alert';
import { OrgsMap } from './common/TypeMap';
import {
  currentChannelType,
} from '../types';


const styles = theme => {
	return {
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    blockContent: {
      padding: '24px'
    },
    copyField: {
      display: 'flex',
    },
    copyPointer: {
      cursor: 'pointer',
      marginLeft: '8px'
    }
	};
};

export class BlockDetail extends Component {
  constructor(props) {
		super(props);
		this.state = {
      blockDetail: {},
		};
	}

  componentWillMount() {
    const blockDetail = this.props.location?.state;
    if (!blockDetail) {
      this.props.history.goBack();
    } else {
      this.setState({ blockDetail })
    }
	}

  componentDidUpdate(prevProps, prevState) {
		if (this.props.currentChannel !== prevProps.currentChannel) {
		}
	}

  handleClick = () => {
    this.setState({ showAlert: true })
  }

  render() {
    const { classes } = this.props;
    const { blockDetail, showAlert } = this.state;

    return (
      <View>
        <QueueAnim type="bottom">
          <Card key="card">
            <CardHeader
              title={
                <div className={classes.cardHeader}>
                  <Box
                    component="img"
                    src={blockIcon}
                    sx={{ height: 32, mr: '16px' }}
                  />
                  <Typography variant="subtitle1">区块：#{blockDetail?.blocknum}</Typography>
                </div>
              }
            />
            <Divider />
            <div className={classes.blockContent}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">交易数量</Typography>
                  <Typography variant="h4">{blockDetail?.txcount}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">创建时间</Typography>
                  <Typography variant="subtitle1">{moment(blockDetail?.createdt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">区块大小（KB）</Typography>
                  <Typography variant="subtitle1">{blockDetail?.blksize}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" color="textSecondary">通道名称</Typography>
                  <Typography variant="subtitle1">{blockDetail?.channelname}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">区块哈希</Typography>
                  <div className={classes.copyField}>
                    <Typography variant="subtitle1" noWrap={true}>{blockDetail?.blockhash}</Typography>
                    <CopyToClipboard text={blockDetail?.blockhash} onCopy={this.handleClick}>
                      <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                    </CopyToClipboard>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">前一区块哈希</Typography>
                  <div className={classes.copyField}>
                    <Typography variant="subtitle1" noWrap={true}>{blockDetail?.prehash}</Typography>
                    <CopyToClipboard text={blockDetail?.prehash} onCopy={this.handleClick}>
                      <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                    </CopyToClipboard>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="subtitle2" color="textSecondary">数据哈希</Typography>
                  <div className={classes.copyField}>
                    <Typography variant="subtitle1" noWrap={true}>{blockDetail?.datahash}</Typography>
                    <CopyToClipboard text={blockDetail?.datahash} onCopy={this.handleClick}>
                      <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                    </CopyToClipboard>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="subtitle2" color="textSecondary">交易哈希</Typography>
                  {
                    blockDetail?.txhash && blockDetail.txhash.length > 0 && blockDetail.txhash.map(item => (
                      <div className={classes.copyField}>
                        <NavLink to={`/transactionDetail/${item}`}>
                          <Typography variant="subtitle1" noWrap={true}>{item}</Typography>
                        </NavLink>
                        <CopyToClipboard text={item} onCopy={this.handleClick}>
                          <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                        </CopyToClipboard>
                      </div>
                    ))
                  }
                </Grid>
              </Grid>
            </div>
          </Card>
        </QueueAnim>
        <Alert
          type="success"
          text="数据已复制"
          open={showAlert}
          closeBar={() => this.setState({ showAlert: false })}
        />
      </View>
    );
  }
}

BlockDetail.propTypes = {
  currentChannel: currentChannelType.isRequired,
};

export default withStyles(styles)(BlockDetail);
