import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import find from 'lodash/find';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import QueueAnim from 'rc-queue-anim';
import { filter } from 'lodash';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ReplayIcon from '@material-ui/icons/Replay';
import View from '../Styled/View';
import TableSortHead from './components/TableSortHead';
import Alert from './components/Alert';
import EmptyImg from '../../static/images/img-empty.svg';
import blockIcon from '../../static/images/ico-block.png';
import {
  blockListType,
  currentChannelType,
  getTransactionType,
  transactionType,
} from '../types';

import Blocks from '../Lists/Blocks';
import { OrgsMap } from './common/TypeMap';

const styles = theme => {
	return {
    queryCard: {
      padding: '24px',
      marginBottom: '24px',
    },
    queryForm: {
    },
    textSelectField: {
      minWidth: '150px'
    },
    blockTable: {
      paddingBottom: '16px',
		},
    tableRoot: {
      padding: '0 16px 16px',
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    hashCellRoot: {
      display: 'block',
      width: '10vw'
    },
    buttonGroup: {
    },
    button: {
      padding: '8px 16px',
      marginLeft: '8px'
    },
		EmptyImg: {
			width: '12vw',
			margin: '0 auto'
		}
	};
};

const TABLE_HEAD = [
  { id: 'blocknum', label: '区块序号' },
  { id: 'createdt', label: '创建时间' },
  { id: 'txcount', label: '交易数量' },
  { id: 'datahash', label: '数据哈希' },
  { id: 'blockhash', label: '区块哈希' },
  { id: 'prehash', label: '前一区块哈希' },
  { id: 'txhash', label: '交易哈希' },
  { id: 'blksize', label: '区块大小（KB）' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export class BlocksView extends Component {
  constructor(props) {
		super(props);
		this.state = {
      page: 0,
      rowsPerPage: 10,
      order: 'desc',
      orderBy: '',
      startTime: moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm'), // 开始时间
      endTime: moment().format('YYYY-MM-DDTHH:mm'), // 结束时间
      timeError: false, // 开始结束时间是否正确
      orgs: 'all', // 查询机构
      orgsOptions: [], // 查询机构数组
		};
	}

  componentWillMount() {
  }

  componentDidMount() {
    // 初始化所有组织机构
		const orgsOptions = [];
		this.props.transactionByOrg.forEach(item => {
			orgsOptions.push({
        label: (OrgsMap[item.creator_msp_id] || item.creator_msp_id), value: item.creator_msp_id
      });
		});
		this.setState({ orgsOptions });
    // 有查询条件 显示按查询条件查询的列表
    if (this.props.blockListSearchParams) {
      this.setState({
        search: this.props.blockListSearchParams.search,
        page: this.props.blockListSearchParams.page,
        rowsPerPage: this.props.blockListSearchParams.rowsPerPage,
        order: this.props.blockListSearchParams.order,
        orderBy: this.props.blockListSearchParams.orderBy,
        startTime: this.props.blockListSearchParams.startTime,
        endTime: this.props.blockListSearchParams.endTime,
        orgs: this.props.blockListSearchParams.orgs
      });
    }
	}

  componentDidUpdate(prevProps, prevState) {
		if (
			this.state.search &&
			this.props.currentChannel !== prevProps.currentChannel
		) {
			this.searchBlockList(this.props.currentChannel);
		}
	}

  // 输入框变化
  handleChange = event => {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    const newState = {
      [name]: value
    };
    this.setState(newState);
  };

  // 提交查询
  submitForm = async e => {
    e.preventDefault();
    if (this.state.startTime > this.state.endTime) {
      this.setState({
        showAlert: true,
        showAlertText: '开始时间不能大于结束时间'
      })
    } else {
      await this.searchBlockList();
      this.setState({ search: true });
    }
  }

  searchBlockList = async channel => {
		let query = `from=${new Date(this.state.startTime).toString()}&&to=${new Date(
			this.state.endTime
		).toString()}`;
    // 选择所有则不加组织，否则加上组织ID
    if (this.state.orgs != 'all') {
      query += `&&orgs=${this.state.orgs}`;
    }
		let channelhash = this.props.currentChannel;
		if (channel !== undefined) {
			channelhash = channel;
		}
    this.setBlockListSearchParams({ ...this.state })
		await this.props.getBlockListSearch(channelhash, query);
	};

  // 重置查询
  resetForm = () => {
    this.setBlockListSearchParams(null)  // 清空查询条件
		this.setState({
			search: false,
      page: 0,
      rowsPerPage: 10,
      order: 'desc',
      orderBy: '',
      timeError: false,
      startTime: moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm'), // 开始时间
      endTime: moment().format('YYYY-MM-DDTHH:mm'), // 结束时间
      orgs: 'all', // 机构全部
		});
	};

  // 存储当前页面状态 查询条件（当前页State）
  setBlockListSearchParams(params) {
    this.props.setBlockListSearchParams(params || null)
  };

  // 跳区块详情页
  goBlockDetail = (row) => {
    this.props.history.push({
      pathname: '/blockDetail',
      state: row
    });
  }

  render() {
    const {
      classes,
      currentChannel,
      getTransaction,
      transaction,
      blockListSearch,
      getBlockListSearch,
      transactionByOrg,
    } = this.props;
    const { page, order, orderBy, rowsPerPage, orgsOptions } = this.state;
    const blockList = this.state.search
      ? this.props.blockListSearch
      : this.props.blockList;
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      this.setState({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      }, () => {
        this.setBlockListSearchParams({ ...this.state })
      })
    };
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      }, () => {
        this.setBlockListSearchParams({ ...this.state })
      })
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      }, () => {
        this.setBlockListSearchParams({ ...this.state })
      })
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - blockList.length) : 0;
    const filteredList = applySortFilter(blockList, getComparator(order, orderBy));
    const isListNotFound = filteredList.length === 0;

    return (
      <View>
        <Card classes={{ root: classes.queryCard }}>
          <form className={classes.queryForm} onSubmit={this.submitForm}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <FormControl>
                  <TextField
                    id="startTime"
                    name="startTime"
                    label="开始时间"
                    type="datetime-local"
                    value={this.state.startTime}
                    className={classes.textTimeField}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => this.handleChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    id="endTime"
                    name="endTime"
                    label="结束时间"
                    type="datetime-local"
                    value={this.state.endTime}
                    className={classes.textTimeField}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => this.handleChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel>组织</InputLabel>
                  <Select
                    id="orgs"
                    name="orgs"
                    value={this.state.orgs}
                    className={classes.textSelectField}
                    onChange={e => this.handleChange(e)}
                  >
                    <MenuItem value={'all'}>全部</MenuItem>
                    {
                      orgsOptions.length > 0 && orgsOptions.map(item => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs align="right">
                <div className={classes.buttonGroup}>
                  <Button
                    type="submit"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                  >
                    查询
                  </Button>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    startIcon={<ReplayIcon />}
                    onClick={() => { this.resetForm() }}
                  >
                    重置
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Card>
        <QueueAnim type="bottom">
          <Card key="card">
            <div className={classes.blockTable}>
              <CardHeader
                title={
                  <div className={classes.cardHeader}>
                    <Box
                      component="img"
                      src={blockIcon}
                      sx={{ height: 32, mr: '16px' }}
                    />
                    <Typography variant="subtitle1">区块列表</Typography>
                  </div>
                }
              />
              <Divider />
              <TableContainer classes={{ root: classes.tableRoot }}>
                <Table>
                  <TableSortHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                          >
                            <TableCell>{row.blocknum}</TableCell>
                            <TableCell>{moment(row.createdt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                            <TableCell>{row.txcount}</TableCell>
                            <TableCell>
                              <Typography classes={{ root: classes.hashCellRoot }} variant="inherit" noWrap={true}>{row.datahash}</Typography>
                            </TableCell>
                            <TableCell>
                              <a className="router" onClick={() => this.goBlockDetail(row)}>
                                <Tooltip title={row.blockhash} arrow placement="top">
                                  <Typography classes={{ root: classes.hashCellRoot }} variant="inherit" noWrap={true}>
                                    {row.blockhash}
                                  </Typography>
                                </Tooltip>
                              </a>
                            </TableCell>
                            <TableCell>
                              <Tooltip title={row.prehash} arrow placement="top">
                                <Typography classes={{ root: classes.hashCellRoot }} variant="inherit" noWrap={true}>
                                  {row.prehash}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {
                                row.txhash.map(item => (
                                  <NavLink to={`/transactionDetail/${item}`}>
                                    <Tooltip title={item} arrow placement="top">
                                      <Typography classes={{ root: classes.hashCellRoot }} variant="inherit" noWrap={true}>{item}</Typography>
                                    </Tooltip>
                                  </NavLink>
                                ))
                              }
                            </TableCell>
                            <TableCell>{row.blksize}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={8} />
                      </TableRow>
                    )}
                    {isListNotFound && (
											<TableRow>
												<TableCell colSpan={8}>
													<Box sx={{ textAlign: 'center'  }}>
														<img className={classes.EmptyImg} alt="未查到数据" src={EmptyImg} />
														<Typography variant="body2" color="textSecondary">未查到数据</Typography>
													</Box>
												</TableCell>
											</TableRow>
                  	)}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={blockList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>`第${from}-${to}条 共${count}条`}
              />
            </div>
          </Card>
        </QueueAnim>
        <Alert
          type="error"
          text={this.state.showAlertText}
          open={this.state.showAlert}
          closeBar={() => this.setState({ showAlert: false })}
        />
        {
          /*
            <Blocks
              blockList={blockList}
              currentChannel={currentChannel}
              transaction={transaction}
              getTransaction={getTransaction}
              transactionByOrg={transactionByOrg}
              blockListSearch={blockListSearch}
              getBlockListSearch={getBlockListSearch}
            />
          */
        }
      </View>
    );
  }
}

BlocksView.propTypes = {
  blockList: blockListType.isRequired,
  currentChannel: currentChannelType.isRequired,
  getTransaction: getTransactionType.isRequired,
  transaction: transactionType,
};

BlocksView.defaultProps = {
  transaction: null,
};

export default withStyles(styles)(BlocksView);
