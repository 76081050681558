import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, Typography, ButtonGroup, Button, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import TransactionChart from '../../../Charts/TransactionChart';
import { chartSelectors, chartOperations } from '../../../../state/redux/charts';
import {
	currentChannelType,
	getTransactionPerHourType,
	getTransactionPerMinType,
	transactionPerHourType,
	transactionPerMinType
} from '../../../types';

const {
	currentChannelSelector,
	transactionPerHourSelector,
	transactionPerMinSelector,
} = chartSelectors;

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	return {
		chart: {
		},
		cardHeaderRoot: {
			padding: '16px',
		},
		cardHeaderAction: {
			marginTop: '0',
			marginRight: '0',
		}
	};
};

export class TransactionChartCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chartType: 'HOUR'
		};
	}

	componentDidMount() {
		this.interVal = setInterval(() => {
			const { currentChannel } = this.props;
			this.syncData(currentChannel);
		}, 60000);
	}

	componentWillUnmount() {
		clearInterval(this.interVal);
	}

	syncData = currentChannel => {
		const {
			getTransactionPerHour,
			getTransactionPerMin,
		} = this.props;

		getTransactionPerHour(currentChannel);
		getTransactionPerMin(currentChannel);
	};

	timeDataSetup = (chartData = []) => {
		let dataMax = 0;
		const blockData = chartData.map(data => {
			if (parseInt(data.count, 10) > dataMax) {
				dataMax = parseInt(data.count, 10);
			}
			// 交易时间增加八个小时
			return {
				x: data.datetime,
				y: data.count,
			}
		});

		return {
			dataMax: (parseInt(dataMax/2)+1)*2,
			blockData,
		};
	};

	handleChangeType(type) {
		this.setState({
			chartType: type
		})
	}


	render() {
		const {
			transactionPerHour,
			transactionPerMin,
			classes
		} = this.props;
		const { chartType } = this.state;

		return (
			<div className={classes.chart}>
				<CardHeader
					classes={{
						root: classes.cardHeaderRoot,
						action: classes.cardHeaderAction
					}}
					title={<Typography variant="subtitle1">最近生成交易</Typography>}
					action={
						<ButtonGroup size="medium" color="secondary">
							<Button
								variant={chartType === 'HOUR' ? 'contained' : 'outlined'}
								onClick={(e) => this.handleChangeType('HOUR')}
							>
								每小时
							</Button>
							<Button
								variant={chartType === 'MIN' ? 'contained' : 'outlined'}
								onClick={(e) => this.handleChangeType('MIN')}
							>
								每分钟
							</Button>
						</ButtonGroup>
					}
				/>
				<Divider />
				<TransactionChart
					chartData={
						chartType === 'HOUR' ?
						this.timeDataSetup(transactionPerHour)
						:
						this.timeDataSetup(transactionPerMin)
					}
				/>
			</div>
		);
	}
}

TransactionChartCard.propTypes = {
	currentChannel: currentChannelType.isRequired,
	transactionPerHour: transactionPerHourType.isRequired,
	transactionPerMin: transactionPerMinType.isRequired,
	getTransactionPerHour: getTransactionPerHourType.isRequired,
	getTransactionPerMin: getTransactionPerMinType.isRequired,
};

const mapStateToProps = state => {
	return {
		currentChannel: currentChannelSelector(state),
		transactionPerHour: transactionPerHourSelector(state),
		transactionPerMin: transactionPerMinSelector(state),
	};
};

const mapDispatchToProps = {
	getTransactionPerHour: chartOperations.transactionPerHour,
	getTransactionPerMin: chartOperations.transactionPerMin,
};

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(TransactionChartCard);
export default withStyles(styles)(connectedComponent);
