import React from 'react';
import { Row, Col } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import QueueAnim from 'rc-queue-anim';
import View from '../Styled/View';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Grid,
  Box,
  Tooltip
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import Alert from './components/Alert';
import chainIcon from '../../static/images/ico-security.png';
import { chaincodeListType } from '../types';

const styles = theme => {
	return {
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    blockContent: {
      padding: '24px'
    },
    copyField: {
      display: 'flex',
    },
    copyPointer: {
      cursor: 'pointer',
      marginLeft: '8px'
    }
	};
};

export const ChaincodeView = ({ chaincodeList, classes }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <View>
      <QueueAnim type="bottom">
        <Row key="row">
          {
            chaincodeList.map((row, index) => {
              return (
                <Col sm="6">
                    <Card>
                      <CardHeader
                        title={
                          <div className={classes.cardHeader}>
                            <Box
                              component="img"
                              src={chainIcon}
                              sx={{ height: 40, mr: '24px' }}
                            />
                            <div>
                              <Typography variant="subtitle2" color="textSecondary">链码名</Typography>
                              <Typography variant="h6">{row.chaincodename}</Typography>
                            </div>
                          </div>
                        }
                      />
                      <Divider />
                      <div className={classes.blockContent}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="subtitle2" color="textSecondary">交易数量</Typography>
                            <Typography variant="h4">{row.txCount}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="subtitle2" color="textSecondary">通道</Typography>
                            <Typography variant="subtitle1">{row.channelName}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="subtitle2" color="textSecondary">版本</Typography>
                            <Typography variant="subtitle1">v {row.version}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="subtitle2" color="textSecondary">路径</Typography>
                            <Typography variant="subtitle1">{row.path}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <Typography variant="subtitle2" color="textSecondary">通道创世哈希</Typography>
                            <div className={classes.copyField}>
                              <Tooltip title={row.channel_genesis_hash} arrow placement="top">
                                <Typography variant="subtitle2" noWrap={true}>{row.channel_genesis_hash}</Typography>
                              </Tooltip>
                              <CopyToClipboard text={row.channel_genesis_hash} onCopy={handleClick}>
                                <FileCopyTwoToneIcon classes={{ root: classes.copyPointer }} color="primary" />
                              </CopyToClipboard>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                </Col>
              )
            })
          }
        </Row>
      </QueueAnim>
      <Alert
        type="success"
        text="数据已复制"
        open={open}
        closeBar={handleClose}
      />
    </View>
  );
}

ChaincodeView.propTypes = {
  chaincodeList: chaincodeListType.isRequired,
};

export default withStyles(styles)(ChaincodeView);
