// ----------------------------------------------------------------------

export default function Lists(theme) {
  return {
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        minWidth: 'auto',
        marginRight: theme.spacing(2)
      }
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 'auto',
        marginRight: theme.spacing(2)
      }
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0
      },
      multiline: {
        marginTop: 0,
        marginBottom: 0
      }
    }
  };
}
