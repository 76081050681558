import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItem
} from '@material-ui/core';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
// utils
import IconChat from '../../static/images/icon_notification_chat.svg';
import EmptyImg from '../../static/images/img-empty.svg';
// components
import MenuPopover from '../common/MenuPopover';

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: 1,
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'order_placed',
    createdAt: '',
    isUnRead: false
  },
  {
    id: 2,
    title: 'asdkfaskdfj',
    description: 'answered to your comment on the Minimal',
    avatar: null,
    type: 'friend_interactive',
    createdAt: '',
    isUnRead: false
  },
  {
    id: 3,
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: '',
    isUnRead: false
  },
  {
    id: 4,
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: '',
    isUnRead: false
  },
  {
    id: 5,
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: '',
    isUnRead: false
  }
];

const useStyles = makeStyles((theme) => ({
  IconButton: {
    padding: 0,
    width: 44,
    height: 44,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lightest,
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.lighter
    }
  },
  IconButtonOpen: {
    padding: 0,
    width: 44,
    height: 44,
    color: '#FFF',
    backgroundColor: theme.palette.primary.lighter,
  },
  EmptyImg: {
    width: '70%',
    margin: '0 auto'
  }
}));

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" color="textSecondary">
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src={IconChat} />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src={IconChat}  />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src={IconChat}  />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src={IconChat}  />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItem
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={null} icon={null} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {notification.createdAt}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default function NotificationsPopover() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        onClick={handleOpen}
        className={open ? classes.IconButtonOpen : classes.IconButton}
        style={{ marginLeft: 12 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        styles={{ width: 320 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>消息中心</Typography>
            {
              /*
              <Typography variant="body2" color="textSecondary">
              您有 {totalUnRead} 条未读消息
              </Typography>
              */
            }
          </Box>
        </Box>

        <Divider />
        <Box sx={{ textAlign: 'center', justifyContent: 'center', paddingBottom: 16, py: 1, px: 2 }}>
          <img className={classes.EmptyImg} alt="暂无新消息" src={EmptyImg} />
          <Typography variant="body2" color="textSecondary">暂无新消息</Typography>
        </Box>
        {
          /*
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  New
                </ListSubheader>
              }
            >
              {notifications.slice(0, 2).map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>

            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Before that
                </ListSubheader>
              }
            >
              {notifications.slice(2, 5).map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>

          <Divider />

          <Box sx={{ p: 1 }}>
            <Button fullWidth disableRipple component={RouterLink} to="#">
              View All
            </Button>
          </Box>
        */
      }
      </MenuPopover>
    </>
  );
}
