// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      root: {
        boxShadow: theme.customShadows.z16,
        backgroundImage: 'none'
      },
      elevation1: {
        boxShadow: theme.customShadows.z16,
      }
    }
  };
}
