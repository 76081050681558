import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, Typography, ButtonGroup, Button, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import classnames from 'classnames';
import BlockChart from '../../../Charts/BlockChart';
import { chartSelectors, chartOperations } from '../../../../state/redux/charts';
import {
	currentChannelType,
	getBlocksPerHourType,
	getBlocksPerMinType,
  blockPerHourType,
  blockPerMinType,
} from '../../../types';

const {
	currentChannelSelector,
	blockPerHourSelector,
	blockPerMinSelector,
} = chartSelectors;

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		chart: {
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#453e68' : undefined
		},
		cardHeaderRoot: {
			padding: '16px',
		},
		cardHeaderAction: {
			marginTop: '0',
			marginRight: '0',
		}
	};
};

export class BlockChartCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chartType: 'HOUR'
		};
	}

	componentDidMount() {
		this.interVal = setInterval(() => {
			const { currentChannel } = this.props;
			this.syncData(currentChannel);
		}, 60000);
	}

	componentWillUnmount() {
		clearInterval(this.interVal);
	}

	syncData = currentChannel => {
		const {
			getBlocksPerHour,
			getBlocksPerMin,
		} = this.props;

    getBlocksPerHour(currentChannel);
		getBlocksPerMin(currentChannel);
	};

	timeDataSetup = (chartData = []) => {
		let dataMax = 0;
		const blockData = chartData.map(data => {
			if (parseInt(data.count, 10) > dataMax) {
				dataMax = parseInt(data.count, 10);
			}
			return {
				x: data.datetime,
				y: data.count,
			}
		});

		return {
			dataMax: (parseInt(dataMax/2)+1)*2,
			blockData,
		};
	};

	handleChangeType(type) {
		this.setState({
			chartType: type
		})
	}

	render() {
		const {
			blockPerHour,
			blockPerMin,
			classes
		} = this.props;
		const { chartType } = this.state;

		return (
			<div className={classes.chart}>
				<CardHeader
					classes={{
						root: classes.cardHeaderRoot,
						action: classes.cardHeaderAction
					}}
					title={<Typography variant="subtitle1">最近生成区块</Typography>}
					action={
						<ButtonGroup size="medium" color="primary">
							<Button
								variant={chartType == 'HOUR' ? 'contained' : 'outlined'}
								onClick={(e) => this.handleChangeType('HOUR')}
							>
								每小时
							</Button>
							<Button
								variant={chartType == 'MIN' ? 'contained' : 'outlined'}
			          onClick={(e) => this.handleChangeType('MIN')}
							>
								每分钟
							</Button>
						</ButtonGroup>
					}
				/>
				<Divider />
				<BlockChart
					chartData={
						chartType == 'HOUR' ?
						this.timeDataSetup(blockPerHour)
						:
						this.timeDataSetup(blockPerMin)
					}
				/>
			</div>
		);
	}
}

BlockChartCard.propTypes = {
	currentChannel: currentChannelType.isRequired,
	blockPerHour: blockPerHourType.isRequired,
	blockPerMin: blockPerMinType.isRequired,
	getBlocksPerHour: getBlocksPerHourType.isRequired,
	getBlocksPerMin: getBlocksPerMinType.isRequired,
};

const mapStateToProps = state => {
	return {
		currentChannel: currentChannelSelector(state),
		blockPerHour: blockPerHourSelector(state),
		blockPerMin: blockPerMinSelector(state),
	};
};

const mapDispatchToProps = {
	getBlocksPerHour: chartOperations.blockPerHour,
	getBlocksPerMin: chartOperations.blockPerMin,
};

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(BlockChartCard);
export default withStyles(styles)(connectedComponent);
