import { useRef, useState } from 'react';
import { createHashHistory } from 'history'; // 如果是hash路由
// material
import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@material-ui/core';
// components
import MenuPopover from '../common/MenuPopover';
import AvatarImg from '../../static/images/avatar.png';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import PersonAddTwoTone from '@material-ui/icons/PersonAddTwoTone';

const useStyles = makeStyles((theme) => ({
  IconButton: {
    padding: 0,
    width: 44,
    height: 44,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  IconButtonOpen: {
    padding: 0,
    width: 44,
    height: 44,
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      backgroundColor: alpha(theme.palette.primary.light, 0.3)
    }
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  menuItemRoot: {
    borderRadius: '6px',
    color: '#212121',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.darker,
      backgroundColor: theme.palette.primary.lightest,
    },
  },
  menuItemLine: {
    display: 'flex',
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  }
}));

// ----------------------------------------------------------------------

export default function AccountPopover({ registerOpen, logout }) {
  const classes = useStyles();
  const history = createHashHistory();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const MENU_OPTIONS = [
    {
      label: '首页',
      icon: <HomeTwoToneIcon classes={{ root: classes.menuIcon }} color="inherit"/>,
      onClick: () => { history.push('/'); setOpen(false); }
    },
    {
      label: '用户管理',
      icon: <PersonAddTwoTone classes={{ root: classes.menuIcon }} color="inherit" />,
      onClick: () => { registerOpen(); setOpen(false); }
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={open ? classes.IconButtonOpen : classes.IconButton}
        style={{ marginLeft: 12 }}
      >
        <Avatar src={AvatarImg} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        styles={{ width: 260 }}
      >
        <Box sx={{ my: 1, px: 2 }}>
          <Typography variant="subtitle1" noWrap>
            管理员
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            admin@chaorun.net
          </Typography>
        </Box>

        <Divider classes={{ root: classes.divider }} />

        {MENU_OPTIONS.map((item) => (
          <MenuItem
            key={item.label}
            component={'span'}
            onClick={item.onClick}
            classes={{
              root: classes.menuItemRoot
            }}
          >
            <div className={classes.menuItemLine}>
              {item.icon}
              {item.label}
            </div>
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="primary" variant="contained" onClick={logout}>
            退出登录
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
