// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      root: {
        boxShadow: theme.customShadows.z16,
        borderRadius: theme.shape.borderRadiusSm,
        position: 'relative',
        zIndex: 0 // Fix Safari overflow: hidden with border radius
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' }
      },
      root: {
      }
    },
    MuiCardContent: {
      root: {
      }
    }
  };
}
