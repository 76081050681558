/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';

import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import WifiTetheringOutlinedIcon from '@material-ui/icons/WifiTetheringOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import loginBg from '../../static/images/login-bg.jpg';
import crLogo from '../../static/images/cr-logo.png';
import BeianImg from '../../static/images/ico-beian.png';

import { shape, string } from 'prop-types';

import { authSelectors, authOperations } from '../../state/redux/auth';

const date = new Date();
const styles = theme => ({
	container: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		position: 'relative',
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(3*2))]: {
			width: 500,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: '14vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(6)}px`
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		marginTop: theme.spacing(3),
		padding: '12px 22px',
		boxShadow: '0 8px 16px 0 rgb(25 165 156 / 30%)'
	},
	errortext: {
		fontSize: 16,
		font: 'bold',
		color: 'red'
	},
	loginBg: {
		height: '100vh',
		width: '100vw',
		position: 'absolute',
		top: 0,
		left: 0,
		background: `url(${loginBg}) 50%`,
		backgroundSize: 'cover',
		overflow: 'hidden',
	},
	loginBg: {
		height: '100vh',
		width: '100vw',
		position: 'absolute',
		top: 0,
		left: 0,
		background: `url(${loginBg}) 50%`,
		backgroundSize: 'cover',
		overflow: 'hidden',
		'&:before': {
			width: '100%',
			height: '100%',
			content: '""',
			background: 'rgba(0,0,0,.35)',
			display: 'block',
		}
	},
	loginFooter: {
		color: '#AAA',
		width: '100%',
		position: 'absolute',
		bottom: 24,
		display: 'flex',
		justifyContent: 'center',
	},
	loginFooterBA: {
		display: 'flex',
		justifyContent: 'center',
		color: '#AAA',
		alignItems: 'center',
	},
	loginFooterDivider: {
		margin: '0 12px',
	},
	loginFooterBAImg: {
		width: '16px',
		marginRight: '8px',
	}
});

export class Login extends Component {
	static propTypes = {
		classes: shape({
			avatar: string,
			form: string,
			container: string,
			paper: string,
			submit: string
		}).isRequired
	};

	constructor(props) {
		super(props);
		const { networks = [] } = props;
		this.state = {
			info: null,
			user: {
				error: null,
				value: ''
			},
			password: {
				error: null,
				value: ''
			},
			network: {
				error: null,
				value: '',
				id: ''
			},
			autoLoginAttempted: false,
			error: '',
			networks,
			authEnabled: false,
			isLoading: false,
			showPassword: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const { networks = [] } = nextProps;
		this.setState(() => ({
			networks,
			network: {
				error: null,
				value: networks[0].name || '',
				id: networks[0].id
			},
			authEnabled: networks[0].authEnabled
		}));
	}

	handleChange = event => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		const newState = {
			[name]: { value }
		};
		if (name === 'network') {
			const { networks } = this.state;
			newState.authEnabled = (
				networks.find(n => n.name === value) || {}
			).authEnabled;
			newState.network.id = (networks.find(n => n.name === value) || {}).id;
		}

		this.setState(newState);
	};

	async performLogin({ user, password, network }) {
		const { login } = this.props;
		const { authEnabled } = this.state;

		const info = await login(
			{
				user: authEnabled ? user : 'dummy-user',
				password: authEnabled ? password : 'dummy-password'
			},
			network
		);

		this.setState(() => ({ info }));
		if (info.status === 'Success') {
			const { history } = this.props;
			history.replace('/');
			return true;
		}
	}

	submitForm = async e => {
		e.preventDefault();

		const { user, password, network } = this.state;

		await this.performLogin({
			user: user.value,
			password: password.value,
			network: network.id
		});
	};

	async componentDidUpdate() {
		const { networks, autoLoginAttempted } = this.state;

		/*
		 * If we have only one network and it doesn't have auth enabled, perform a login
		 * autoLoginAttempted is a safety to prevent multiple tries
		 */
		if (
			networks.length === 1 &&
			!networks[0].authEnabled &&
			!autoLoginAttempted
		) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState(() => ({
				autoLoginAttempted: true
			}));
			await this.performLogin({ network: networks[0].id });
		}
	}

	handleClickShowPassword = () => {
		this.setState({
			showPassword: !this.state.showPassword
		})
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

	render() {
		const {
			info,
			user,
			password,
			network,
			networks,
			authEnabled,
			isLoading,
			showPassword
		} = this.state;
		const { classes, error } = this.props;

		return (
			<div>
				<div className={classes.loginBg} />
				<div className={classes.container}>
					<Paper className={classes.paper}>
						<img src={crLogo} />
						<form className={classes.form} onSubmit={this.submitForm}>
							<FormControl margin="normal" required fullWidth>
								<TextField
									required
									fullWidth
									select
									id="network"
									name="network"
									label="网络"
									variant="outlined"
									disabled={isLoading}
									value={network.value}
									onChange={e => this.handleChange(e)}
									margin="normal"
									InputLabelProps={{ required: false }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<WifiTetheringOutlinedIcon />
											</InputAdornment>
										),
										shrink: 'true'
									}}
								>
									{networks.map(item => (
										<MenuItem key={item.name} value={item.name}>
											{item.name}
										</MenuItem>
									))}
								</TextField>
								{network.error && (
									<FormHelperText id="component-error-text" error>
										{network.error}
									</FormHelperText>
								)}
							</FormControl>
							{authEnabled && (
								<FormControl margin="normal" required fullWidth>
									<TextField
										error={!!user.error}
										required
										fullWidth
										id="user"
										name="user"
										label="用户名"
										disabled={isLoading}
										value={user.value}
										onChange={e => this.handleChange(e)}
										margin="normal"
										variant="outlined"
										InputLabelProps={{ required: false }}
									/>
									{user.error && (
										<FormHelperText id="component-error-text" error>
											{user.error}
										</FormHelperText>
									)}
								</FormControl>
							)}
							{authEnabled && (
								<FormControl margin="normal" required fullWidth>
									<TextField
										required
										fullWidth
										error={!!password.error}
										id="password"
										type={showPassword ? 'text' : 'password'}
										name="password"
										label="密码"
										disabled={isLoading}
										value={password.value}
										onChange={e => this.handleChange(e)}
										margin="normal"
										variant="outlined"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
					                <IconButton
					                  aria-label="toggle password visibility"
					                  onClick={this.handleClickShowPassword}
					                  onMouseDown={this.handleMouseDownPassword}
					                  edge="end"
					                >
					                  {showPassword ? <Visibility /> : <VisibilityOff />}
					                </IconButton>
					              </InputAdornment>
											),
											shrink: 'true'
										}}
										InputLabelProps={{ required: false }}
									/>
									{password.error && (
										<FormHelperText id="component-error-text" error>
											{password.error}
										</FormHelperText>
									)}
								</FormControl>
							)}
							{error && (
								<FormHelperText id="component-error-text" error>
									{error}
								</FormHelperText>
							)}
							{info && (
								<FormHelperText id="component-error-text" className={classes.errortext}>
									{info.message}
								</FormHelperText>
							)}
							<Button
								type="submit"
								size="large"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								{authEnabled ? '登 录' : '连 接'}
							</Button>
						</form>
					</Paper>
				</div>
				<div className={classes.loginFooter}>
					Copyright © 2020-{date.getFullYear()} 上海超润网络科技有限公司
					<div className={classes.loginFooterDivider}>|</div>
					<a className={classes.loginFooterBA} target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009951">
						<img className={classes.loginFooterBAImg} src={BeianImg} />
						<span>沪公网安备 31010402009951号</span>
					</a>
					<div className={classes.loginFooterDivider}>|</div>
					区块链信息服务备案 沪网信备31012022801483150014号
				</div>
			</div>
		);
	}
}

const { authSelector, errorSelector, networkSelector } = authSelectors;

const mapStateToProps = state => {
	return {
		auth: authSelector(state),
		error: errorSelector(state),
		networks: networkSelector(state)
	};
};

const mapDispatchToProps = {
	login: authOperations.login
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Login);
export default withStyles(styles)(connectedComponent);
