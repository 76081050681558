import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import QueueAnim from 'rc-queue-anim';
import { filter } from 'lodash';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ReplayIcon from '@material-ui/icons/Replay';
import View from '../Styled/View';
import TableSortHead from './components/TableSortHead';
import Alert from './components/Alert';
import EmptyImg from '../../static/images/img-empty.svg';
import transIcon from '../../static/images/ico-transaction.png';
import {
	currentChannelType,
	getTransactionType,
	getTransactionInfoType,
	getTransactionListType,
	transactionType,
	transactionListType
} from '../types';

import Transactions from '../Lists/Transactions';
import { OrgsMap, TransTypeMap } from './common/TypeMap';

const styles = theme => {
	return {
    queryCard: {
      padding: '24px',
      marginBottom: '24px',
    },
    queryForm: {
    },
    textSelectField: {
      minWidth: '150px'
    },
    transactionTable: {
      paddingBottom: '16px',
		},
    tableRoot: {
      padding: '0 16px 16px',
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    hashCellRoot: {
      display: 'block',
    },
    buttonGroup: {
    },
    button: {
      padding: '8px 16px',
      marginLeft: '8px'
    },
		EmptyImg: {
			width: '12vw',
			margin: '0 auto'
		}
	};
};

const TABLE_HEAD = [
  { id: 'txhash', label: '交易哈希' },
	{ id: 'createdt', label: '创建时间' },
	{ id: 'creator_msp_id', label: '创建者' },
	{ id: 'type', label: '交易类型' },
  { id: 'channelname', label: '通道名称' },
  { id: 'chaincodename', label: '链码名称' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export class TransactionsView extends Component {
  constructor(props) {
		super(props);
		this.state = {
      page: 0,
      order: 'desc',
      orderBy: '',
      rowsPerPage: 10,
      startTime: moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm'), // 开始时间
      endTime: moment().format('YYYY-MM-DDTHH:mm'), // 结束时间
      timeError: false, // 开始结束时间是否正确
      orgs: 'all', // 查询机构
      orgsOptions: [], // 查询机构数组
		};
	}

  componentDidMount() {
    // 初始化所有组织机构
		const orgsOptions = [];
    this.props.transactionByOrg.forEach(item => {
      orgsOptions.push({
        label: (OrgsMap[item.creator_msp_id] || item.creator_msp_id), value: item.creator_msp_id
      });
    });
		this.setState({ orgsOptions });
    // 有查询条件 显示按查询条件查询的列表
    if (this.props.transactionListSearchParams) {
      this.setState({
        search: this.props.transactionListSearchParams.search,
        page: this.props.transactionListSearchParams.page,
        rowsPerPage: this.props.transactionListSearchParams.rowsPerPage,
        order: this.props.transactionListSearchParams.order,
        orderBy: this.props.transactionListSearchParams.orderBy,
        startTime: this.props.transactionListSearchParams.startTime,
        endTime: this.props.transactionListSearchParams.endTime,
        orgs: this.props.transactionListSearchParams.orgs
      });
    }
	}

  componentDidUpdate(prevProps, prevState) {
		if (
			this.state.search &&
			this.props.currentChannel !== prevProps.currentChannel
		) {
			this.searchTransactionList(this.props.currentChannel);
		}
	}

  // 输入框变化
  handleChange = event => {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    const newState = {
      [name]: value
    };
    this.setState(newState);
  };

  // 提交查询
  submitForm = async e => {
    e.preventDefault();
    if (this.state.startTime > this.state.endTime) {
      this.setState({
        showAlert: true,
        showAlertText: '开始时间不能大于结束时间'
      })
    } else {
      await this.searchTransactionList();
  		this.setState({ search: true });
    }
  }

  searchTransactionList = async channel => {
		let query = `from=${new Date(this.state.startTime).toString()}&&to=${new Date(
			this.state.endTime
		).toString()}`;
    // 选择所有则不加组织，否则加上组织ID
    if (this.state.orgs != 'all') {
      query += `&&orgs=${this.state.orgs}`;
    }
		let channelhash = this.props.currentChannel;
		if (channel !== undefined) {
			channelhash = channel;
		}
    this.setTransactionListSearchParams({ ...this.state })
		await this.props.getTransactionListSearch(channelhash, query);
	};

  // 重置查询
  resetForm = () => {
    this.setTransactionListSearchParams(null)  // 清空查询条件
		this.setState({
			search: false,
      page: 0,
      rowsPerPage: 10,
      order: 'desc',
      orderBy: '',
      timeError: false,
      startTime: moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm'), // 开始时间
      endTime: moment().format('YYYY-MM-DDTHH:mm'), // 结束时间
      orgs: 'all', // 机构全部
		});
	};

  // 存储当前页面状态 查询条件（当前页State）
  setTransactionListSearchParams(params) {
    this.props.setTransactionListSearchParams(params || null)
  };

  render() {
    const {
			classes,
			currentChannel,
			getTransaction,
			getTransactionInfo,
			getTransactionList,
			transaction,
			getTransactionListSearch,
			transactionByOrg,
			transactionListSearch,
			transactionId,
			removeTransactionId
    } = this.props;
    const { page, order, orderBy, rowsPerPage, orgsOptions } = this.state;
    const transactionList = this.state.search
      ? this.props.transactionListSearch
      : this.props.transactionList;
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      this.setState({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      }, () => {
        this.setTransactionListSearchParams({ ...this.state })
      })
    };
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      }, () => {
        this.setTransactionListSearchParams({ ...this.state })
      })
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      }, () => {
        this.setTransactionListSearchParams({ ...this.state })
      })
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionList.length) : 0;
    const filteredList = applySortFilter(transactionList, getComparator(order, orderBy));
    const isListNotFound = filteredList.length === 0;

    return (
      <View>
        <Card classes={{ root: classes.queryCard }}>
          <form className={classes.queryForm} onSubmit={this.submitForm}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <FormControl>
                  <TextField
                    id="startTime"
                    name="startTime"
                    label="开始时间"
                    type="datetime-local"
                    value={this.state.startTime}
                    className={classes.textTimeField}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => this.handleChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    id="endTime"
                    name="endTime"
                    label="结束时间"
                    type="datetime-local"
                    value={this.state.endTime}
                    className={classes.textTimeField}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => this.handleChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel>组织</InputLabel>
                  <Select
                    id="orgs"
                    name="orgs"
                    value={this.state.orgs}
                    className={classes.textSelectField}
                    onChange={e => this.handleChange(e)}
                  >
                    <MenuItem value={'all'}>全部</MenuItem>
                    {
                      orgsOptions.length > 0 && orgsOptions.map(item => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs align="right">
                <div className={classes.buttonGroup}>
                  <Button
                    type="submit"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                  >
                    查询
                  </Button>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    startIcon={<ReplayIcon />}
                    onClick={() => { this.resetForm() }}
                  >
                    重置
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Card>
        <QueueAnim type="bottom">
          <Card key="card">
            <div className={classes.transactionTable}>
              <CardHeader
                title={
                  <div className={classes.cardHeader}>
                    <Box
                      component="img"
                      src={transIcon}
                      sx={{ height: 32, mr: '16px' }}
                    />
                    <Typography variant="subtitle1">交易列表</Typography>
                  </div>
                }
              />
              <Divider />
              <TableContainer classes={{ root: classes.tableRoot }}>
                <Table>
                  <TableSortHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                          >
														<TableCell>
                              <NavLink to={`/transactionDetail/${row.txhash}`}>
                                <Tooltip title={row.txhash} arrow placement="top">
                                  <Typography classes={{ root: classes.hashCellRoot }} variant="inherit" noWrap={true}>{row.txhash}</Typography>
                                </Tooltip>
                              </NavLink>
                            </TableCell>
														<TableCell>{moment(row.createdt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
														<TableCell>{OrgsMap[row?.creator_msp_id] || row?.creator_msp_id}</TableCell>
														<TableCell>{TransTypeMap[row.type] || row.type }</TableCell>
                            <TableCell>{row.channelname}</TableCell>
														<TableCell>{row.chaincodename}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                    {isListNotFound && (
											<TableRow>
												<TableCell colSpan={6}>
													<Box sx={{ textAlign: 'center'  }}>
														<img className={classes.EmptyImg} alt="未查到数据" src={EmptyImg} />
														<Typography variant="body2" color="textSecondary">未查到数据</Typography>
													</Box>
												</TableCell>
											</TableRow>
                  	)}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={transactionList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>`第${from}-${to}条 共${count}条`}
              />
            </div>
          </Card>
        </QueueAnim>
        <Alert
          type="error"
          text={this.state.showAlertText}
          open={this.state.showAlert}
          closeBar={() => this.setState({ showAlert: false })}
        />
        {
          /*
						<Transactions
							currentChannel={currentChannel}
							transactionList={transactionList}
							getTransactionList={getTransactionList}
							transaction={transaction}
							transactionByOrg={transactionByOrg}
							getTransactionInfo={getTransactionInfo}
							getTransaction={getTransaction}
							getTransactionListSearch={getTransactionListSearch}
							transactionListSearch={transactionListSearch}
							transactionId={transactionId}
							removeTransactionId={removeTransactionId}
						/>
          */
        }
      </View>
		);
	}
}

TransactionsView.propTypes = {
	currentChannel: currentChannelType.isRequired,
	getTransaction: getTransactionType.isRequired,
	getTransactionInfo: getTransactionInfoType.isRequired,
	getTransactionList: getTransactionListType.isRequired,
	transaction: transactionType.isRequired,
	transactionList: transactionListType.isRequired
};

export default withStyles(styles)(TransactionsView);
