import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import QueueAnim from 'rc-queue-anim';
import { filter } from 'lodash';
import { useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box
} from '@material-ui/core';
import View from '../Styled/View';
import { peerListType } from '../types';
import TableSortHead from './components/TableSortHead';
import Label from '../common/Label';
import netIcon from '../../static/images/ico-net.png';
import { OrgsMap, PeerTypeMap } from './common/TypeMap';

const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
    blockTable: {
      paddingBottom: '16px',
		},
    tableRoot: {
      padding: '0 16px 16px',
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
    },
	};
};

const TABLE_HEAD = [
  { id: 'server_hostname', label: '节点名称' },
  { id: 'requests', label: '请求地址' },
  { id: 'peer_type', label: '节点类型' },
  { id: 'mspid', label: '组织成员' },
  { id: 'ledger_height_high', label: '账本高度（HIGH）' },
  { id: 'ledger_height_low', label: '账本高度（LOW）' },
  { id: 'Unsigned', label: '连接状态' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const NetworkView = ({ peerList, classes }) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - peerList.length) : 0;
  const filteredList = applySortFilter(peerList, getComparator(order, orderBy));
  const isListNotFound = filteredList.length === 0;

  return (
    <View>
      <QueueAnim type="bottom">
        <Card key="card">
          <div className={classes.blockTable}>
            <CardHeader
              title={
                <div className={classes.cardHeader}>
                  <Box
                    component="img"
                    src={netIcon}
                    sx={{ height: 32, mr: '16px' }}
                  />
                  <Typography variant="subtitle1">网络节点概况</Typography>
                </div>
              }
            />
            <Divider />
            <TableContainer classes={{ root: classes.tableRoot }}>
              <Table>
                <TableSortHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                        >
                          <TableCell>{row.server_hostname && row.server_hostname.split(":")[0]}</TableCell>
                          <TableCell>{row.requests}</TableCell>
                          <TableCell>
                            {
                              PeerTypeMap[row.peer_type] ?
                              `${PeerTypeMap[row.peer_type]}（${row.peer_type}）`
                              :
                              row.peer_type
                            }
                          </TableCell>
                          <TableCell>{OrgsMap[row.mspid] || row.mspid}</TableCell>
                          <TableCell>{row.ledger_height_high}</TableCell>
                          <TableCell>{row.ledger_height_low}</TableCell>
                          {
                            row.ledger_height_unsigned == true ?
                            <TableCell>
                              <Label variant="ghost" color="success">已连接</Label>
                            </TableCell>
                            :
                            (
                              row.ledger_height_unsigned == false ?
                              <TableCell>
                                <Label variant="ghost" color="error">未连接</Label>
                              </TableCell>
                              :
                              <TableCell>{row.ledger_height_unsigned}</TableCell>
                            )
                          }
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {isListNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        没数据
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={peerList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>`第${from}-${to}条 共${count}条`}
            />
          </div>
        </Card>
      </QueueAnim>
    </View>
  );
}

NetworkView.propTypes = {
  peerList: peerListType.isRequired,
};

export default withStyles(styles)(NetworkView);
